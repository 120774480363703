/**
 * Created by vladislav on 29.10.2020
 */

var CoinBlock = function (x, y, keypad, type) {
    BaseBlock.call(this, x, y, keypad);
    this.type = type;
    this.amount = 1;
    if (this.type === CoinBlock.TYPE_BAG) {
        this.amount = cleverapps.Random.random(CoinBlock.BAG_COINS[0], CoinBlock.BAG_COINS[1]);
    }
};

CoinBlock.prototype = Object.create(BaseBlock.prototype);
CoinBlock.prototype.constructor = CoinBlock;

CoinBlock.prototype.getViewClass = function () {
    if (this.type === CoinBlock.TYPE_BAG) {
        return CoinBagBlockView;
    }
    return CoinBlockView;
};

CoinBlock.prototype.collect = function () {
    var prize = CoinBlock.COIN_REWARD * this.amount;

    var reward = new Reward("hard", prize, { event: cleverapps.EVENTS.EARN.BOARD_COIN });
    reward.receiveReward();
    reward.collectRewardsAnimation(this.onLocateView(), {
        flyingAnimation: Reward.COLLECT_ANIMATION
    });
};

CoinBlock.process = function () {
    if (!Game.currentGame) {
        return;
    }

    var keypad = Game.currentGame.keypad;

    var coinsBlocks = [];

    if (keypad.words.length === 0) {
        keypad.field.forEach(function (column) {
            column.forEach(function (block) {
                if (block instanceof CoinBlock) {
                    coinsBlocks.push(block);
                }
            });
        });
    } else {
        coinsBlocks = keypad.field.map(function (column) {
            return column[0];
        }).filter(function (block) {
            return block instanceof CoinBlock;
        });
    }

    if (coinsBlocks.length === 0) {
        return;
    }

    cleverapps.Random.shuffle(coinsBlocks).forEach(function (coinBlock, index) {
        Game.currentGame.counter.setTimeout(function () {
            coinBlock.collect();

            keypad.removeBlock(coinBlock.x, coinBlock.y);
        }, index * 20 + Math.random() * 20);
    });

    if (keypad.words.length === 0) {
        Game.currentGame.counter.setTimeout(function () {
        }, 1000);
    }
};

CoinBlock.COIN_REWARD = 1;
CoinBlock.BAG_COINS = [7, 15];

CoinBlock.TYPE_COIN = "*";
CoinBlock.TYPE_BAG = "#";